import React, { useState, useEffect } from "react";
import { Wrapper, Grid, StyledTitle } from "../styles";
import * as S from "./styles";
import * as T from "components/layout/titles";
import { items } from "./items";
import { getProcessData } from "http-services/data-access";
import useConfig from "services/config/useConfig";
import { FormattedNumber } from 'react-intl';
import { isAfter, subMonths } from "date-fns";

type Props = {
  upn: string;
};

const ProcessData = ({ upn }: Props) => {
  const { translation, language } = useConfig();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const translateItem = (key: string) => {
    const translationItems = Object.entries(translation.map.search.caseData);
    const data = translationItems.find(([k]) => k === key);

    if (data) {
      return data[1][language];
    }
  };

  const Data = items.map(({ label, key, format }) => {
    const v = data?.[key];
    const value = format ? format(v) : v;

    return (
      <S.DataItem key={key}>
        <T.ItemTitle>{translateItem(key) || label}</T.ItemTitle>
        <span>{value || "Não há dados para serem exibidos"}</span>
      </S.DataItem>
    );
  });


  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getProcessData(upn);

        setData(data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    setData({});
    getData();
    setLoading(true);
  }, [upn]);

  const cfemLast12Months = (data?.cfem ?? []).filter((item: { value: string, year: string, month: string }) => {
    return isAfter(subMonths(new Date(), 12), new Date(`${item.year}-${item.month}-01`));
  }).reduce((acc: number, item: { value: string }) => acc + parseFloat(item.value), 0);

  const volumeLast12Months = ((data?.cfem ?? []).filter((item: { volume: string, year: string, month: string }) => {
    return isAfter(subMonths(new Date(), 12), new Date(`${item.year}-${item.month}-01`));
  }).reduce((acc: number, item: { volume: string }) => acc + parseFloat(item.volume), 0)).toFixed(2);


  const { unit } = (data?.cfem ?? []).find((item: { unit: string }) => item.unit) ?? { unit: "kg" };

  return (
    <Wrapper>
      <StyledTitle>
        {translation.map.search.caseData.title[language]}
      </StyledTitle>
      {loading ? "Carregando..." : <Grid>
        {Data}
        <S.DataItem key={"CFEM"}>
          <T.ItemTitle>{translateItem("cfem") || "CFEM (Últimos 12 meses)"}</T.ItemTitle>
          <span>{cfemLast12Months ? <FormattedNumber value={cfemLast12Months} style="currency" currency="BRL" /> : "Não há dados para serem exibidos"}</span>
        </S.DataItem>
        <S.DataItem key={"Volume"}>
          <T.ItemTitle>{translateItem("volume") || "Volumetria de Minério Declarado (Últimos 12 meses)"}</T.ItemTitle>
          <span>{volumeLast12Months ? <><FormattedNumber value={volumeLast12Months} style="unit" /> <span>{unit}</span></> : "Não há dados para serem exibidos"}</span>
        </S.DataItem>
      </Grid>}
    </Wrapper>
  );
};

export default ProcessData;
