import { FC, memo, useEffect, useState, PropsWithoutRef, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { LayerStyle, LayerStyleGroups } from "../../../../services/layers";
import _ from "lodash";
import useConfig from "services/config/useConfig";
import { Languages } from "types/config/i18n";

type Activatable = {
  isActive?: boolean;
};

export type SubtitleProps = PropsWithoutRef<{
  title?: string;
  groups: LayerStyleGroups;

  /**
   * Returns the set of "names" from the
   * provided grouped styles that are currently active.
   *
   * It returns the values ungrouped.
   */
  onFiltersChange: (filters: string[]) => void;

  onFrontierToggle?: (isOn: boolean) => void;
  onSatelliteToggle?: (isOn: boolean) => void;
  onLegalAmazonToggle?: (isOn: boolean) => void;
  onStateToggle?: (isOn: boolean) => void;
  onEmbargoToggle?: (isOn: boolean) => void;
  onPlgToggle?: (isOn: boolean) => void;
}>;

function addActivatable<T>(obj: T): T & Activatable {
  return {
    ...obj,
    isActive: false,
  };
}

/**
 * Creates a subtitle for map view to enable layer filtering
 */
const LayerFilter: FC<SubtitleProps> = ({
  title = "Legendas",
  groups: initialGroups,
  onFiltersChange,
  onFrontierToggle,
  onSatelliteToggle,
  onLegalAmazonToggle,
  onEmbargoToggle,
  onStateToggle,
  onPlgToggle,
}) => {
  const { language, translation } = useConfig();

  const [groups, setGroups] = useState<LayerStyleGroups<Activatable>>(
    Object.keys(initialGroups)
      .map((name) => ({
        [name]: initialGroups[name].map(addActivatable),
      }))
      .reduce(
        (g, value) => ({
          ...g,
          ...value,
        }),
        {},
      ),
  );
  const [expanded, setExpanded] = useState<string | false>(false);

  /**
   * Sets the expanded panel to match the provided panel
   */
  const onAccordionChange = (panel: string) => () => {
    setExpanded(expanded === panel ? false : panel);
  };

  /**
   * Changes a single style isActive property
   * within the group name to checked.
   *
   * @param name
   * @param style
   * @param checked
   * @returns
   */
  const toggle = (name: string, style: LayerStyle, checked: boolean) => {
    const newGroups = {
      ...groups,
    };

    const group = [...(newGroups?.[name] ?? [])];
    const layerStyle = group.find((s) => s.name === style.name);

    if (!layerStyle) return;

    layerStyle.isActive = checked;

    newGroups[name] = group;

    setGroups(newGroups);
    onFiltersChange(getActiveFilters(newGroups));
  };

  /**
   * Changes all styles within group name
   * to isActive = checked.
   *
   * @param name
   * @param checked
   */
  const toggleAll = (name: string, checked: boolean) => {
    const newGroups = {
      ...groups,
    };

    const styles = [...(newGroups?.[name] ?? [])].map((v) => ({
      ...v,
      isActive: checked,
    }));

    newGroups[name] = styles;

    const filters = getActiveFilters(newGroups);
    setGroups(newGroups);
    onFiltersChange(filters);
  };

  const getActiveFilters = (groups: LayerStyleGroups<Activatable>) => {
    return Object.keys(groups)
      .map((key) => groups[key])
      .flatMap((a) => a.filter((v) => v.isActive))
      .map((v) => v.name);
  };

  const translateTitle = (name: string): string => {
    const translationTitlesData = Object.values(translation.map.subtitle);

    const translatData = translationTitlesData.find(
      (t) => t[Languages.PORTUGUESE].toLowerCase() === name.toLowerCase(),
    );
    return translatData?.[language] || name;
  };

  return (
    <Accordion
      sx={{
        height: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          height: "4rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: "#da9121",
          }}
        >
          {title === "Legendas" ? translation.map.subtitle.title[language] : translateTitle(title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          className="scrollhost"
          sx={{
            maxHeight: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              borderBottom: "1px solid lightgray",
              paddingY: 1,
            }}
          >
            <Checkbox defaultChecked onChange={(_, checked) => onPlgToggle?.(checked)}></Checkbox>
            <Typography>{translateTitle("Processos (ANM)")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              borderBottom: "1px solid lightgray",
              paddingY: 1,
            }}
          >
            <Checkbox onChange={(_, checked) => onStateToggle?.(checked)}></Checkbox>
            <Typography>{translateTitle("Divisa de Estados")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              borderBottom: "1px solid lightgray",
              paddingY: 1,
            }}
          >
            <Checkbox onChange={(_, checked) => onSatelliteToggle?.(checked)}></Checkbox>
            <Typography>{translateTitle("Satélite")}</Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              borderBottom: "1px solid lightgray",
              paddingY: 1,
            }}
          >
            <Checkbox onChange={(_, checked) => onLegalAmazonToggle?.(checked)}></Checkbox>
            <Typography>{translateTitle("Faixa Tampão Amazônia Legal")}</Typography>
          </Box> */}
          {groups &&
            Object.keys(groups).map((name) => (
              <Accordion
                key={translateTitle(name)}
                expanded={expanded === name}
                onChange={() => { }}
                disableGutters={true}
                square={true}
              >
                <AccordionSummary
                  sx={{
                    p: 0,
                    borderBottom: "none !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      alignItems: "center",
                    }}
                    onClick={() => (expanded === name ? setExpanded(false) : setExpanded(name))}
                  >
                    <Checkbox
                      value={name}
                      indeterminate={
                        groups[name].some((f) => !f.isActive) &&
                        groups[name].some((f) => f.isActive)
                      }
                      checked={groups[name].every((f) => f.isActive)}
                      onChange={(_, checked) => toggleAll(name, checked)}
                    ></Checkbox>
                    <Typography>{translateTitle(name)}</Typography>
                    <IconButton
                      sx={{
                        marginLeft: "auto",
                      }}
                      onClick={onAccordionChange(name)}
                    >
                      {expanded === name && <ExpandLess />}
                      {expanded !== name && <ExpandMore />}
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {groups[name].map((filter) => (
                    <Box
                      key={filter.name}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        key={filter.name}
                        value={filter.name}
                        checked={filter.isActive}
                        onChange={(_, checked) => toggle(name, filter, checked)}
                      />
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "8px",
                          background: filter.color,
                          m: 1,
                        }}
                      ></Box>
                      <Typography>{translateTitle(filter.name)}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LayerFilter;
