import { Languages } from "types/config/i18n";

export const search = {
  tabs: {
    search: {
      [Languages.PORTUGUESE]: "Buscar",
      [Languages.ENGLISH]: "Search",
    },
    filter: {
      [Languages.PORTUGUESE]: "Filtrar",
      [Languages.ENGLISH]: "Filter",
    },
  },
  filter: {
    title: {
      [Languages.PORTUGUESE]: "Filtrar pacote por",
      [Languages.ENGLISH]: "Filter package by",
    },
    pkg: {
      [Languages.PORTUGUESE]: "Pacote",
      [Languages.ENGLISH]: "Package",
    },
    option: {
      analysisRequested: {
        [Languages.PORTUGUESE]: "Análise solicitada",
        [Languages.ENGLISH]: "Analysis requested",
      },
      packageFit: {
        [Languages.PORTUGUESE]: "Pacote I Apto",
        [Languages.ENGLISH]: "Package I Fit",
      },
      none: {
        [Languages.PORTUGUESE]: "Nenhum",
        [Languages.ENGLISH]: "None",
      },
    },
    resultTitle: {
      [Languages.PORTUGUESE]: "Resultados",
      [Languages.ENGLISH]: "Results",
    },
    noResults: {
      [Languages.PORTUGUESE]: "Nenhum resultado encontrado.",
      [Languages.ENGLISH]: "No results found.",
    },
    modal: {
      title: {
        [Languages.PORTUGUESE]: "Filtrar por",
        [Languages.ENGLISH]: "Filter by",
      },
      description: {
        [Languages.PORTUGUESE]: "Selecione os critérios que devem ser válidos.",
        [Languages.ENGLISH]: "Select the criteria that must be valid.",
      },
      action: {
        [Languages.PORTUGUESE]: "Aplicar",
        [Languages.ENGLISH]: "Apply",
      },
      close: {
        [Languages.PORTUGUESE]: "Resetar",
        [Languages.ENGLISH]: "Reset",
      },
    }
  },
  caseData: {
    title: {
      [Languages.PORTUGUESE]: "Dados do processo",
      [Languages.ENGLISH]: "Data from case",
    },
    nup: {
      [Languages.PORTUGUESE]: "NUP (Número Único do Processo)",
      [Languages.ENGLISH]: "NUP (Single Case Number)",
    },
    applicant: {
      [Languages.PORTUGUESE]: "Requerente / Titular",
      [Languages.ENGLISH]: "Applicant / Holder",
    },
    situation: {
      [Languages.PORTUGUESE]: "Situação",
      [Languages.ENGLISH]: "Situation",
    },
    area: {
      [Languages.PORTUGUESE]: "Área",
      [Languages.ENGLISH]: "Area",
    },
    lastEvent: {
      [Languages.PORTUGUESE]: "Último evento",
      [Languages.ENGLISH]: "Last event",
    },
    substance: {
      [Languages.PORTUGUESE]: "Substâncias",
      [Languages.ENGLISH]: "Substances",
    },
    county: {
      [Languages.PORTUGUESE]: "Município",
      [Languages.ENGLISH]: "Municipality(ies)",
    },
    phase: {
      [Languages.PORTUGUESE]: "Fase",
      [Languages.ENGLISH]: "Phase",
    },
    cfem: {
      [Languages.PORTUGUESE]: "CFEM (Últimos 12 meses)",
      [Languages.ENGLISH]: "CFEM (Last 12 months)",
    },
    volume: {
      [Languages.PORTUGUESE]: "Volumetria de Minério Declarado (Últimos 12 meses)",
      [Languages.ENGLISH]: "Declared Mineral Volume (Last 12 months)",
    },
  },
  modal: {
    tabs: {
      analysisCriteria: {
        [Languages.PORTUGUESE]: "Critérios de análise",
        [Languages.ENGLISH]: "Analysis criteria",
      },
      dataCase: {
        [Languages.PORTUGUESE]: "Dados do processo",
        [Languages.ENGLISH]: "Data from case",
      },
      events: {
        [Languages.PORTUGUESE]: "Eventos",
        [Languages.ENGLISH]: "Events",
      },
      relatedPeople: {
        [Languages.PORTUGUESE]: "Pessoas Relacionadas",
        [Languages.ENGLISH]: "Related People",
      },
    },
  },
};

export const subtitle = {
  title: {
    [Languages.PORTUGUESE]: "Legenda",
    [Languages.ENGLISH]: "Subtitle",
  },
  anm: {
    [Languages.PORTUGUESE]: "Processos (ANM)",
    [Languages.ENGLISH]: "Processes (ANM)",
  },
  frontier: {
    [Languages.PORTUGUESE]: "Faixa de Fronteira",
    [Languages.ENGLISH]: "Frontier Band",
  },
  indigenous: {
    [Languages.PORTUGUESE]: "Terras Indígenas",
    [Languages.ENGLISH]: "Indigenous Lands",
  },
  approved: {
    [Languages.PORTUGUESE]: "Homologadas",
    [Languages.ENGLISH]: "Approved",
  },
  notApproved: {
    [Languages.PORTUGUESE]: "Não Homologadas",
    [Languages.ENGLISH]: "Not approved",
  },
  indigenouseStudy: {
    [Languages.PORTUGUESE]: "Terra Indígena em Estudo",
    [Languages.ENGLISH]: "Indigenous Land under study",
  },
  indigenouseReserve: {
    [Languages.PORTUGUESE]: "Reserva Indigena",
    [Languages.ENGLISH]: "Indigenous Reserve",
  },
  indigenousLand: {
    [Languages.PORTUGUESE]: "Terras Dominiais Indígenas",
    [Languages.ENGLISH]: "Indigenous Domain Lands",
  },
  ecoStation: {
    [Languages.PORTUGUESE]: "Estação Ecológica",
    [Languages.ENGLISH]: "Ecological Station",
  },
  bioReserve: {
    [Languages.PORTUGUESE]: "Reserva Biológica",
    [Languages.ENGLISH]: "Biological Reserve",
  },
  nationalPark: {
    [Languages.PORTUGUESE]: "Parque Nacional",
    [Languages.ENGLISH]: "National Park",
  },
  naturalMonument: {
    [Languages.PORTUGUESE]: "Monumento Natural",
    [Languages.ENGLISH]: "Natural Monument",
  },
  wildlife: {
    [Languages.PORTUGUESE]: "Refúgio de Vida Silvestre",
    [Languages.ENGLISH]: "Wildlife Refugee",
  },
  envProtec: {
    [Languages.PORTUGUESE]: "Unidades de uso Sustentável",
    [Languages.ENGLISH]: "Environmental Protection Units",
  },
  integralProtec: {
    [Languages.PORTUGUESE]: "Unidades de proteção integral",
    [Languages.ENGLISH]: "Integral protection units",
  },
  sustainableUse: {
    [Languages.PORTUGUESE]: "Unidades de uso sustentável",
    [Languages.ENGLISH]: "Sustainable Use Units",
  },
  relevantEco: {
    [Languages.PORTUGUESE]: "Área de Relevante Interesse Ecológico",
    [Languages.ENGLISH]: "Area of Relevant Ecological Interest",
  },
  nationalForest: {
    [Languages.PORTUGUESE]: "Floresta Nacional",
    [Languages.ENGLISH]: "National Forest",
  },
  extracReserve: {
    [Languages.PORTUGUESE]: "Reserva Extrativista",
    [Languages.ENGLISH]: "Extractive Reserve",
  },
  sustainableDev: {
    [Languages.PORTUGUESE]: "Reserva de Desenvolvimento Sustentável",
    [Languages.ENGLISH]: "Sustainable Development Reserve",
  },
  natuHeritage: {
    [Languages.PORTUGUESE]: "Reserva Particular do Patrimônio Natural",
    [Languages.ENGLISH]: "Natural Heritage Private Reserve",
  },
  underInjuction: {
    [Languages.PORTUGUESE]: "Áreas sob Interdição",
    [Languages.ENGLISH]: "Areas under injunction",
  },
  embargo: {
    [Languages.PORTUGUESE]: "Embargo Ambiental (IBAMA)",
    [Languages.ENGLISH]: "Environment Embargo",
  },
};
